<script setup lang="ts">
import type { PropType } from 'vue';
import { addressGradientStyles } from '~/utilities/avatar-gradient';

const props = defineProps({
  address: { type: Object as PropType<any>, required: true },
  namedClasses: { type: String as PropType<string>, default: 'font-bold text-neutral-700 dark:text-neutral-200' },
  unnamedClasses: {
    type: String as PropType<string>,
    default: 'tracking-tighter font-mono text-neutral-900 dark:text-neutral-50',
  },
  avatarDimension: { type: String as PropType<string>, default: '1.5rem' },
});
const { address, namedClasses, unnamedClasses, avatarDimension } = toRefs(props);
</script>
<template>
  <div class="inline-flex">
    <NuxtLink v-if="address" :to="`/address/${address.encoded_id}`" :class="['flex items-center gap-1 text-sm']">
      <div
        v-if="avatarDimension"
        :class="[`inline rounded-md`, !address.avatar_uri ? 'flex items-center justify-center bg-gradient-to-br' : '']"
        :style="{ ...addressGradientStyles(address.encoded_id), height: avatarDimension, width: avatarDimension }"
      >
        <img v-if="address.avatar_uri" :src="address.avatar_uri" class="inline rounded-full" />
      </div>
      <span :class="address.name ? namedClasses : unnamedClasses">{{
        address?.name || address.encoded_id.substring(0, 12)
      }}</span>
    </NuxtLink>
  </div>
</template>
